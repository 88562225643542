<script setup lang="ts">
import { approveProForma } from '@/api/proFormas';
import SayErrorResponse from '@/mixins/sayErrorResponse';
import Say from '@/utils/Say'
import {defineEmits, inject, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n()

const emit = defineEmits(['approved', 'cancelForm'])
const props = defineProps({
  invoice: {
    type: Object,
    required: true
  }
})
const form = ref(null as HTMLFormElement | null)
const formValid = ref(false)
// const formRules = ref({
//   confirmationCheck: [
//     value => {
//       if (value) return true
//       return t('pleaseConfirm')
//     },
//   ],
// })
// const confirmationCheck = ref(false)

const dialogTitle = inject('dialogTitle', null)
onMounted(() => {
  dialogTitle.value = t('approveProforma')
})

const formSubmitted = ref(false)
const submit = () => {
  if (formSubmitted.value) {
    return
  }
  formSubmitted.value = true

  // if (form.value.validate() && !formValid.value) {
  //   return
  // }

  approveProForma(props.invoice.id)
    .then(response => {
      if (response.data.error) {
        SayErrorResponse(response);
      } else {
        Say('success', t('proFormaApprovedSuccessfully'));
        emit('approved')
      }
    })
    .catch(error => {
      SayErrorResponse(error);
    })
    .finally(() => {
      formSubmitted.value = false
    });
}
const cancel = () => {
  emit('cancelForm')
}
defineExpose({submit, cancel})

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-');
  return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
}
</script>

<template>
  <div>
    <v-form
      v-model="formValid"
      ref="form"
    >
      <p class="mb-5" v-if="invoice.id">
        {{ t('period') }}:<br>
        <b>
          {{ formatDate(invoice.start_date) }}
          {{ t('upToAndIncludingAbbreviation') }}
          {{ formatDate(invoice.end_date) }}
        </b>
      </p>
      <p class="mb-5" v-if="invoice.id">
        {{ t('number') }}:<br>
        <b>
          {{ invoice.number }}
        </b>
      </p>
      {{ t('confirmApproveProForma') }}

      <!-- <p class="mt-4">{{$t('toConfirm')}}:</p> -->

      <!-- <v-checkbox
        v-model="confirmationCheck"
        :label="t('proFormaApproveLabel')"
        :rules="formRules.confirmationCheck"
        hide-details
      /> -->
    </v-form>
  </div>
</template>

<style scoped lang="scss">

</style>
