<template>
    <td >{{ t('proForma') }}</td>
    <td>
      {{ proForma.number }}
    </td>
    <td>
      <template v-if="proForma.status !== 'CONCEPT'">
        <span @click="openProForma(proForma)" class="pdf-link ml-3">{{ t('open') }}</span>
        <span @click="downloadProForma(proForma)" class="pdf-link ml-3">{{ t('download') }}</span>
      </template>
    </td>
    <td>{{ formatDate(proForma.date) }}</td>
    <td>{{ proForma.status }}</td>
    <td>&euro; {{ formatCurrency(proForma.total_excl_vat) }}</td>
</template>

<script setup lang="ts">
import {defineProps, inject} from 'vue'
import { getProFormaAsPdf } from '@/api/proFormas';
import { useI18n } from 'vue-i18n'
import SayErrorResponse from '@/mixins/sayErrorResponse';

const {t} = useI18n()

const props = defineProps({
  proForma: {
    type: Object,
    required: true
  }
})

const formatDate = inject('formatDate')

const formatCurrency = (value: number) => Number(value).toLocaleString('nl-NL', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const openProForma = async (proForma: {
  number: any; id: any;
}) => {
  const response = await getProFormaAsPdf(proForma.id);

  try {
    // Create a URL from the Blob (binary PDF data)
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');
  } catch (error) {
    SayErrorResponse(error);
  }
}

const downloadProForma = async (proForma: {
  number: any; id: any;
}) => {
  try {
    const response = await getProFormaAsPdf(proForma.id);

    // Create a Blob from the binary PDF data
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Generate a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = pdfUrl;

    // Set the desired file name for the download
    a.download = `${proForma.number}.pdf`;

    // Programmatically trigger a click on the anchor element to download the file
    a.click();

    // Revoke the URL to free up resources
    URL.revokeObjectURL(pdfUrl);
  } catch (error) {
    SayErrorResponse(error);
  }
}
</script>

<style scoped>
.pdf-link {
  cursor: pointer;
  color: rgb(30, 136, 229);
  text-decoration: underline;
}

.checkout-periods-table td {
  padding: 8px 16px;
}
</style>
