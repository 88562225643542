<template>
    <div>
    <template v-if="preLoading">
      <v-row class="align-center justify-center" style="height:calc(100vh - 132px)">
        <v-progress-circular indeterminate color="orange-darken-2" v-show="preLoading" :size="70" :width="4"></v-progress-circular>
      </v-row>
    </template>

    <!-- MAIN FORM -->
    <v-form v-on:submit.prevent="saveJob" v-model="formValid" ref="form">
      <v-row>
        <v-col sm="5" style="position: relative">
          <primary-details-card
            v-if="!preLoading"
            :job="job"
            @change="updatePrimaryDetails"
            @show-client-dialog="showCreateClientDialog"
            @show-location-dialog="showCreateClientLocationDialog"
            ref="primaryDetailsCard"
          />
          <instructions-card
            v-if="!preLoading"
            :job="job"
            @change="updateInstructions"
            ref="instructionsCard"
          />
        </v-col>

        <v-col sm="5" >
          <employees-card
            v-if="!preLoading"
            :job="job"
            @change="updateEmployees"
            @show-contact-dialog="showCreateClientContactDialog"
            ref="employeesCard"
          />
        </v-col>

        <v-col sm="2" class="sidebar">
          <actions-card
            v-if="!preLoading"
            :job="job"
            :loading="preLoading"
            :valid="valid"
            :hasChanges="hasChanges"
            @change="updateStatus"
            @reset="resetJob"
          />
          <status-card
            v-if="!preLoading"
            :job="job"
            @change="updateStatus"
            ref="statusCard"
          />
          <revisions-card
            v-if="!preLoading"
            :job="job"
          />
        </v-col>

      </v-row>
    </v-form>

    <!-- MODALS -->
    <v-dialog
        v-if="showAfterCreateDialog"
        v-model="showAfterCreateDialog"
        max-width="500px"
    >
      <template v-slot:default="{ isActive }">
        <v-card :title="$t('jobCreated')">
          <v-card-text>
            {{ $t('jobAfterCreate') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :text="$t('yes')"
              :onClick="() => router.push({ name: 'WorkOrdersCreate', params: { job_id: job.id } })"
            ></v-btn>
            <v-btn
              :text="$t('no')"
              :onClick="() => router.push({ name: 'JobsEdit', params: { id: job.id } })"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <!-- Add client dialog -->
    <v-dialog v-if="dialog.showClientDialog" v-model="dialog.showClientDialog" max-width="500px" content-class="client-dialog" ref="clientDialog">
      <client-form
        class="client-form"
        :client="newClient"
        :onInput="() => {}"
        :is-dialog="true"
        :submit="createClientLocal"
        ref="clientForm"
        @close="update('showClientDialog', $event)"
      />
    </v-dialog>

    <!-- Add client location dialog -->
    <v-dialog v-if="dialog.showClientLocationDialog" v-model="dialog.showClientLocationDialog" max-width="1000px" content-class="client-location-dialog" ref="clientLocationDialog">
      <client-location-card
        :clientId="job.client_id"
        @created="afterCreateLocation"
        :close="close"
        ref="clientLocationCard"
        :onInput="() => {}"
        @close="update('showClientLocationDialog', $event)"
      />
    </v-dialog>

    <!-- Add client contact dialog -->
    <v-dialog v-if="dialog.showClientContactDialog" v-model="dialog.showClientContactDialog" max-width="500px" content-class="client-contact-dialog" ref="clientContactDialog">
      <client-contact-card
        :clientId="job.client_id"
        :client-contact="clientContactEditing"
        @created="afterCreateClientContact"
        @update="afterUpdateClientContact"
        ref="clientContactCard"
        @close="update('showClientContactDialog', $event)"
      />
    </v-dialog>

  </div>
</template>

<script setup lang="ts">
import PrimaryDetailsCard from '../cards/PrimaryDetailsCard.vue'
import InstructionsCard from '../cards/InstructionsCard.vue'
import EmployeesCard from '../cards/EmployeesCard.vue'
import ActionsCard from '../cards/ActionsCard.vue'
import StatusCard from '../cards/StatusCard.vue'
import RevisionsCard from '../cards/RevisionsCard.vue'

// components for dialogs
import ClientForm from '@/components/Clients/Form.vue'
import ClientLocationCard from '@/components/Clients/LocationCard.vue'
import ClientContactCard from '@/components/Clients/ContactCard.vue'
import { computed, inject, nextTick, onBeforeUnmount, onMounted, provide, ref } from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'
import { createClient } from '@/api/clients'
import { createJob, updateJob } from '@/api/jobs'
import router from '@/router'
import store from '@/store'
import PreventNavigation from '@/utils/PreventNavigation'
import Say from '@/utils/Say'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import emitArray from '@/utils/emit'
import { reactive } from 'vue'
import useBranchSelector from '@/composable/useBranchSelector'
import { watch } from 'vue'
import sayErrorResponse from '@/mixins/sayErrorResponse'
import {useEventBus} from '@vueuse/core'
const createdBus = useEventBus('created');

const { branchSelected } = useBranchSelector()

const emit = defineEmits(emitArray.value)
// Inject from parent
const job: any = inject('job')

// Provide for children
provide('job', job)

const originalJob= ref(null as any)
const newClient= ref({})
const formValid= ref(false)
const preLoading= ref(true)

const showAfterCreateDialog= ref(false)
const dialog = reactive({
  showClientDialog: false,
  showClientLocationDialog: false,
  showClientContactDialog: false
})
const clientContactEditing = ref(null)

const primaryDetailsCard = ref()
const instructionsCard = ref()
const employeesCard = ref()
const statusCard = ref()
const clientForm = ref()
const clientLocationCard = ref()
const clientContactCard = ref()
const suggestedEquipmentCard = ref()
const route = useRoute()
const {t} = useI18n()

const props = defineProps({
  page: {
    type: String,
    required: true
  }
})

watch(() => branchSelected.value, async() => {
  if (props.page === 'create' && job.value) {
    job.value.location_va = vanAmerongenLocations.value[Number(branchSelected.value as any) - 1]
    job.value.branch_id = branchSelected.value
  }
})

watch(() => dialog.showClientContactDialog, async(newValue) => {
  if (!newValue) {
    clientContactEditing.value = null
  }
})

const valid = computed(() => {
  const hasClient = !!job.value.client_id
  const hasInstructions = !!job.value.instructions
  const hasStatus = !!job.value.status
  const hasLocation = !!job.value.location

  return formValid && hasClient && hasInstructions && hasStatus && hasLocation
})
const hasChanges = computed(() => {
  if (!isEqual(originalJob.value, job.value)) {
    return true
  }
  return false
})

const vanAmerongenLocations = computed(() => store.getters['cache.vanAmerongenLocations'])

onMounted (async () => {
  // fetch the location of Van Amerongen (vestigingen) and clients
  // this data is cached in the cache store
  await Promise.all([
    maybeFetchEmployees(),
    maybeFetchClients(),
    maybeFetchVanAmerongenLocations()
  ])

  // see if we are in edit or create mode
  if (route.params && route.params.id) {
    // UPDATE MODE

    await store.dispatch('jobs.fetchSingleForEdit', route.params.id)

    store.commit('app.setPageTitle', t('jobEdit') + ' ' + job.value.job_name)
    store.commit('app.setBreadcrumbs', [
      { text: t('job', 2), routeName: 'JobsList' },
      { text: t('jobEdit') }
    ])
    store.commit('app.setToggleButton', {
      route: { name: 'JobsView', id: route.params.id },
      text: t('see')
    })

    nextTick(() => {
      originalJob.value = cloneDeep(job.value)
    })
  } else {
    // CREATE MODE

    store.commit('app.setPageTitle', t('jobCreate'))
    store.commit('app.setBreadcrumbs', [
      { text: t('job', 2), routeName: 'JobsList' },
      { text: t('jobCreate') }
    ])

    // get an empty template of a wordOrder from the store
    const jobTemplate = store.getters.jobsCreate
    jobTemplate.status = 'PLANNED'
    jobTemplate.location_va = vanAmerongenLocations.value[Number(branchSelected.value as any) - 1]
    jobTemplate.branch_id = branchSelected.value
    originalJob.value = cloneDeep(jobTemplate)
    job.value = cloneDeep(jobTemplate)
  }

  preLoading.value = false

  // Bind browser tab navigation confirmation
  nextTick(() => {
    PreventNavigation.inBrowser(originalJob, job)
  })
})
onBeforeUnmount(() => {
  store.commit('app.clearToggleButton')
})
// Bind app route navigation confirmation
onBeforeRouteLeave ((to, from, next) => {
  PreventNavigation.inVueRouter(originalJob.value, job.value, next)
})

const resetJob = () => {

  nextTick(() => {
    primaryDetailsCard.value.initializeInputs()
    instructionsCard.value.initializeInputs()
    employeesCard.value.initializeInputs()
    statusCard.value.initializeInputs()
    job.value = cloneDeep(originalJob.value)
  })
}

const maybeFetchEmployees = async() => {
  return store.dispatch('cache.employees.fetch')
}
const maybeFetchClients = async() => {
  return store.dispatch('cache.clients.fetch')
}
const maybeFetchVanAmerongenLocations = async() => {
  return store.dispatch('cache.vanAmerongenLocations.fetch')
}
const showCreateClientDialog = () => {
  const clientTemplate = cloneDeep(store.getters.clientsCreate)
  newClient.value = clientTemplate
  dialog.showClientDialog = true
  nextTick(() => {
    clientForm.value.$refs.nameInput.focus()
  })
}
const showCreateClientLocationDialog = () => {
  if (job.value.client_id) {
    dialog.showClientLocationDialog = true
    nextTick(() => {
      clientLocationCard.value.$refs.searchLocationInput.focus()
    })
  }
}
const showCreateClientContactDialog = (clientResponsibleId = null) => {
  if (job.value.client_id) {
    if (clientResponsibleId) {
      clientContactEditing.value = clientResponsibleId
    }
    dialog.showClientContactDialog = true
    nextTick(() => {
      clientContactCard.value.$refs.nameInput.focus()
    })
  }
}
// this function is called BEFORE creating the client
// in the database, in the create client dialog
const createClientLocal = async(client: any) => {
  // create client
  const response: Record<string, any> = await createClient(client)
  if (!response.data) return
  const newClient = response.data

  // update cache
  store.commit('cache.clients.add', newClient)

  // I am emitting a separate event here
  // This problem with the event being emitted by client-form is that there is no waiting for this method to finish
  // and that the even is emitted before the save to API is done, and therefore the id is NULL on the emitted new client.
  // TODO: perhaps resolve this in a better way
  createdBus.emit({ type: 'newClient', data: newClient })

  // set client on job, job.client_id is watched
  // on the PrimaryDetailsCard, which will update locations etc
  //  - There was a watcher on this, but there was also a listener on the created event, the combination caused a problem.
  job.value.client_id = newClient.id
  job.value.client = newClient

  // close modal
  dialog.showClientDialog = false
}
// this function is called AFTER a new location has been created
// in the create location dialog
const afterCreateLocation = (location: any) => {
  if (!location) return

  // update the cache
  const client = store.getters['cache.clients'].find((client: any) => client.id === job.value.client_id)
  client.locations.push(location)
  store.commit('cache.clients.update', client)

  // update location on job.value, job.value.location is watched
  // on the PrimaryDetailsCard, which will update the locations etc
  job.value['location'] = location

  // close modal
  dialog.showClientLocationDialog = false

  // set focus to the next field
  nextTick(() => {
    primaryDetailsCard.value.$refs.clientReferenceInput.focus()
  })
}
// this function is called AFTER a new contact has been created
// in the create location dialog
const afterCreateClientContact = async(contact: any) => {
  if (!location) return

  await employeesCard.value.fetchClientContacts({
    clientId: job.value.client_id
  })
  nextTick(() => {
    employeesCard.value.setResponsibleId(contact.id)
    // close modal
    dialog.showClientContactDialog = false
  })
}
const afterUpdateClientContact = async(contact: any) => {
  if (!location) return

  await employeesCard.value.fetchClientContacts({
    clientId: job.value.client_id
  })

  await nextTick(() => {
    dialog.showClientContactDialog = false
  })
}

const updatePrimaryDetails = (data: any) => {
  // console.log('Updating primary details %o', data)
  if (data.jobName !== undefined) {
    job.value['job_name'] = data.jobName
  }
  if (data.jobDescription !== undefined) {
    job.value['job_description'] = data.jobDescription
  }
  if (data.clientId !== undefined) {
    if(job.value['client_id'] !== data.clientId) {
      employeesCard.value.setResponsibleId(null)
    }
    job.value['client_id'] = data.clientId
  }
  if (data.locationFrom !== undefined) {
    job.value['location_va'] = data.locationFrom
  }
  if (data.locationTo !== undefined) {
    job.value['location'] = data.locationTo
  }
  if (data.clientRef !== undefined) {
    job.value['client_ref'] = data.clientRef
  }
  if (data.useOldInvoicing !== undefined) {
    job.value['use_old_invoicing'] = data.useOldInvoicing
  }
}
const updateInstructions = (data: any) => {
  // console.log('Updating instructions %o', data)
  if (data.instructions !== undefined) {
    job.value['instructions'] = data.instructions
  }
}
const updateEmployees = (data: any) => {
  // console.log('Updating employees %o', data)
  if (data.employeeId !== undefined) {
    job.value['employee_id'] = data.employeeId
  }
  if (data.employees !== undefined) {
    job.value['employees'] = data.employees
  }
  if (data.clientResponsibleId !== undefined && Number.isInteger(data.clientResponsibleId)) {
    job.value['client_responsible_1_id'] = data.clientResponsibleId
  } else if (data.clientResponsibleId === null) {
    // clear field
    job.value['client_responsible_1_id'] = null
  }
}
const updateStatus = (data: any) => {
  // console.log('Updating status %o', data)
  if (data.status !== undefined) {
    job.value['status'] = data.status
  }
}

// create job in the database
const saveJob = async() => {
  const jobLocal = cloneDeep(job.value)
  // Ensure the location is the id
  if (jobLocal.location instanceof Object && jobLocal.location.id !== undefined) {
    jobLocal.location = jobLocal.location.id
  }
  // Ensure the Van Amerongen location is the id
  if (jobLocal.location_va instanceof Object && jobLocal.location_va.id !== undefined) {
    jobLocal.location_va = jobLocal.location_va.id
  }
  // Data which is not to be sent
  const attributesToDelete = [
    'selectedEmployee', 'selectedEmployees', 'selectedResponsible', 'revisions', 'created_by',
    'client', 'employee', 'responsible', 'status_ongoing'
  ]

  attributesToDelete.forEach(attribute => {
    delete jobLocal[attribute]
  })

  if (props.page === 'create') {
    try {
      const response = await createJob(jobLocal)
      const newJob = response.data
      store.commit('jobs.addToList', newJob)
      Say('success', t('projectCreateSuccess'))

      // set job and originalJob to the one we received from the api
      // also to prevent 'unsaved changes' to popup (see PreventNavigation.js)
      originalJob.value = cloneDeep(newJob)
      job.value = cloneDeep(newJob)

      // When creating a new Job, show modal after saving if you want to add a work order to it.
      // 1. If you select 'no' or close the modal, go to 'edit job' of the job just created
      // 2. If you select 'yes' redirect to create new workorder on that job view
      showAfterCreateDialog.value = true

    } catch (err: any) {
      sayErrorResponse(err)
    }
  } else if (props.page === 'edit') {
    try {
      const response = await updateJob(jobLocal.id, jobLocal)
      job.revisions = response.data.revisions

      // make a clone to prevent unsaved changes nag from triggering
      nextTick(() => {
        originalJob.value = cloneDeep(job.value)
      })
      Say('success', t('projectEditSuccess'))
    } catch (err: any) {
      sayErrorResponse(err)
    }
  }

}
const close = () => {
  job.value = false
}

const update = (name: any, value: Boolean) => {
  const dialogLocal: {[key: string]: any} = dialog
  dialogLocal[name] = value
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 10px;
}

.client-dialog .client-form {
  .contacts-projects {
    display: none;
  }

  .flex {
    max-width: 100% !important;
    flex-basis: 100%;
  }
}
</style>
